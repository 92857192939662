
import React from 'react';
import { useNavigate } from 'react-router-dom';
import colorful from '../assets/images/colorful.png';

const Home = () => {
  const navigate = useNavigate();

  const handleCourseRedirect = () => {
    navigate('/#courses'); // Redirect to the courses page with hash
    // Optionally, add smooth scrolling to the section
    setTimeout(() => {
      const element = document.getElementById('courses');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  return (
    <section className="home" id="home">
      <div className="row">
        <div className="content">
          <h3>Welcome to SJS Technologies</h3>
          <p>
            At our institute, we are dedicated to providing high-quality education and learning experiences to all our students. Our mission is to empower individuals with knowledge and skills that will enable them to succeed in their academic and professional endeavors.
          </p>
          <button onClick={handleCourseRedirect} className="btn">
            Learn More
          </button>
        </div>
        <div className="image">
          <img src={colorful} alt="Colorful Design" />
        </div>
      </div>
    </section>
  );
};

export default Home;
