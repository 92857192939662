import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/ConfirmationModal.css'; // Ensure you have this CSS file

const ConfirmationModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={onClose} />
        <div className="confirmation-message">
          <h2>Message Sent Successfully!</h2>
          <p>Thank you for reaching out. We will get back to you soon.</p>
          <button className="btn" onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
