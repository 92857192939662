import React, { useState } from 'react';
import Loader from './Loader'; // Import the Loader component
import ConfirmationModal from './ConfirmationModal'; // Import the ConfirmationModal component

const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Loader state
  const [modalOpen, setModalOpen] = useState(false); // Modal state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.message) formErrors.message = "Message is required";
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Show loader
      try {
        const response = await fetch('https://my-backend-24iw.onrender.com/send-contact-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          console.log('Email sent successfully');
          setFormData({
            email: '',
            message: ''
          });
          setErrors({});
          setModalOpen(true); // Open the modal on success
        } else {
          const errorData = await response.json();
          console.error('Failed to send email:', errorData.error);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false); // Hide loader
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  return (
    <>
      <section className="contact" id="contact">
        <h1 className="heading">
          <span>contact us</span>
        </h1>
        <div className="row">
          <div className="content">
            <h3>Get in touch</h3>
            <p>Please reach out for any inquiries or feedback.</p>
            <p><strong>Address:</strong></p>
            <p>7-1-40/41, Flat No. 3, Level 3
              Opp. Vijaya Ganapati Temple
              Towards KK Convention, Suncity
              Hydershakote, Hyderabad, TS INDIA – 500091.</p>
            <p><strong>Telephone:</strong></p>
            <p>(123) 456-7890</p>
            <p><strong>Email:</strong></p>
            <p>hello@example.com</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="inputBox">
              <span className="fas fa-envelope"></span>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="inputBox">
              <span className="fas fa-comment"></span>
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
              />
              {errors.message && <p className="error">{errors.message}</p>}
            </div>
            <input type="submit" value="contact now" className="btn" />
            {loading && <Loader />} {/* Show loader when loading */}
          </form>
        </div>
      </section>

      {/* Render Confirmation Modal */}
      {modalOpen && <ConfirmationModal onClose={handleCloseModal} />}
    </>
  );
};

export default Contact;
