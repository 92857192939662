import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faUsers, faBookOpen, faCertificate } from '@fortawesome/free-solid-svg-icons';

const EducationContent = () => {
  return (
    <section className="features-section" id="education-content">
      <div className="container">
     
         <h3 className="heading">
        <span>Key Features</span>
      </h3>
        <div className="row">
          <div className="col">
            <div className="feature-item">
              <FontAwesomeIcon icon={faGraduationCap} size="3x" />
              <h3>Expert Instructors</h3>
              <p>Learn from industry experts who bring real-world experience  into the classroom.</p>
            </div>
          </div>
          <div className="col">
            <div className="feature-item">
              <FontAwesomeIcon icon={faUsers} size="3x" />
              <h3>Diverse Community</h3>
              <p>Join a diverse community of learners and professionals from around the globe.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="feature-item">
              <FontAwesomeIcon icon={faBookOpen} size="3x" />
              <h3>Comprehensive Curriculum</h3>
              <p>Range of courses designed to meet the demands of today's industries.</p>
            </div>
          </div>
          <div className="col">
            <div className="feature-item">
              <FontAwesomeIcon icon={faCertificate} size="3x" />
              <h3>Recognition</h3>
              <p>Earn certificates upon completion to showcase your skills and advance your career.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationContent;
