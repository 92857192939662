
import React from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useLocation, Link,useNavigate } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();


  // const scrollToSection = (section) => {
  //   if (location.pathname !== "/") {
  //     navigate(`/#${section}`);
  //   } else {
  //     const element = document.getElementById(section);
  //     if (element) {
  //       scroll.scrollTo(element.offsetTop);
  //     }
  //   }
   
  // };
  const scrollToSection = (section) => {
  if (location.pathname !== "/") {
    // Navigate to the home page and then scroll without adding hash to the URL
    navigate("/", { replace: true });
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        scroll.scrollTo(element.offsetTop);
      }
    }, 100); // Adjust the timeout as needed
  } else {
    const element = document.getElementById(section);
    if (element) {
      scroll.scrollTo(element.offsetTop);
    }
  }
};


  const handleLinkClick = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100); // Adjust the delay as needed
  };

  return (
    <section className="footer">
      <div className="share">
        <a href="#home" aria-label="Share on Facebook" className="fab fa-facebook-f">
          <span className="visually-hidden">Share on Facebook</span>
        </a>
        <a href="#home" aria-label="Share on Twitter" className="fab fa-twitter">
          <span className="visually-hidden">Share on Twitter</span>
        </a>
        <a href="#home" aria-label="Share on Instagram" className="fab fa-instagram">
          <span className="visually-hidden">Share on Instagram</span>
        </a>
        <a href="#home" aria-label="Share on LinkedIn" className="fab fa-linkedin">
          <span className="visually-hidden">Share on LinkedIn</span>
        </a>
        <a href="#home" aria-label="Share on Pinterest" className="fab fa-pinterest">
          <span className="visually-hidden">Share on Pinterest</span>
        </a>
      </div>
      
      <div className="links">
        <ScrollLink to="home" smooth={false} duration={500} onClick={() => scrollToSection('home')}>
          Home
        </ScrollLink>
        <Link to="/aboutus" onClick={handleLinkClick}>About Us</Link>
        <ScrollLink to="courses" smooth={false} duration={500} onClick={() => scrollToSection('courses')}>
          Courses
        </ScrollLink>
     
        <ScrollLink to="contact" smooth={false} duration={500} onClick={() => scrollToSection('contact')}>
          Contact Us
        </ScrollLink>
      </div>

      <div className="credit">
        <span>SJS Technologies</span> | All Rights Reserved
      </div>
    </section>
  );
};

export default Footer;

