// src/components/Loader.js
import React from 'react';
import '../assets/css/Loader.css'; // Create a corresponding CSS file for the loader

const Loader = () => (
  <div className="loader-overlay">
    <div className="loader"></div>
  </div>
);

export default Loader;
