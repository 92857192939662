
import React, { useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import "../assets/css/styles.css";
import sjs from "../assets/images/sjs.png";

const Navbar = () => {
  const navbarRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();



  const navbarHandler = () => {
    navbarRef.current.classList.toggle("active");  
  };

  // const scrollToSection = (section) => {
  //   if (location.pathname !== "/") {
  //     navigate(`/#${section}`);
  //   } else {
  //     const element = document.getElementById(section);
  //     if (element) {
  //       scroll.scrollTo(element.offsetTop);
  //     }
  //   }
  //   navbarHandler(); // Close the navbar after clicking a link
  // };
  const scrollToSection = (section) => {
  if (location.pathname !== "/") {
    // Navigate to the home page and then scroll without adding hash to the URL
    navigate("/", { replace: true });
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        scroll.scrollTo(element.offsetTop);
      }
    }, 100); // Adjust the timeout as needed
  } else {
    const element = document.getElementById(section);
    if (element) {
      scroll.scrollTo(element.offsetTop);
    }
  }
  navbarHandler(); // Close the navbar after clicking a link
};

  const handleLinkClick = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100); // Adjust the delay as needed
    navbarHandler(); // Close the navbar after clicking a link
  };

  return (
    <>
      <header className="header">
        <div className="logo-container">
          <ScrollLink
            to="/"
            smooth={false}
            duration={500}
            onClick={() => scrollToSection("home")}
          >
            <img src={sjs} alt="Logo" width="70" height="70" />
          </ScrollLink>
          <Link to="/" onClick={handleLinkClick} className="brand-name">
            SJS Technologies
          </Link>
        </div>
        <nav className="navbar" ref={navbarRef}>
          <ScrollLink
            to="home"
            smooth={false}
            duration={500}
            onClick={() => scrollToSection("home")}
          >
            Home
          </ScrollLink>
          <Link to="/aboutus" onClick={handleLinkClick}>About Us</Link>
          <ScrollLink
            to="courses"
            smooth={false}
            duration={500}
            onClick={() => scrollToSection("courses")}
          >
            Courses
          </ScrollLink>
          <ScrollLink
            to="contact"
            smooth={false}
            duration={500}
            onClick={() => scrollToSection("contact")}
          >
            Contact Us
          </ScrollLink>
        </nav>
        <div className="icons">
          <div className="fas fa-bars" id="menu-btn" onClick={navbarHandler}></div>
        </div>
      </header>
    </>
  );
}; 

export default Navbar;
