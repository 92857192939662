
import { faPython, faReact, faAngular, faJsSquare } from '@fortawesome/free-brands-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

const courses = {
  python: {
    name: "Python",
    description: "Learn Python, a versatile language for web development, data science, and AI. Master syntax, data structures, and libraries.",
    className: "python",
    icon: faPython,
    fee: 15000,
    duration: "3 months",
    prerequisites: [
      "Basic programming concepts",
      "Familiarity with Object-Oriented Programming (OOP)",
      "Understanding of data structures and algorithms",
    ],
    content: [
      "Introduction to Python and its syntax",
      "Data manipulation and analysis with pandas",
      "Web scraping with BeautifulSoup",
      "Creating APIs with Flask",
      "Building GUI applications with Tkinter",
      "Using Django for web development",
    ],
    targetAudience: "This course is ideal for beginners and intermediate developers looking to master Python for various applications."
  },
  reactjs: {
    name: "React.js",
    description: "Dive into React.js, a library for building dynamic UIs. Understand components, state management, and routing.",
    className: "reactjs",
    icon: faReact,
    fee: 20000,
    duration: "2 months",
    prerequisites: [
      "Basic knowledge of HTML, CSS, and JavaScript",
      "Familiarity with ES6 syntax",
    ],
    content: [
      "Introduction to React.js",
      "Components, Props, and State",
      "Lifecycle Methods",
      "Hooks and Context API",
      "Routing with React Router",
      "State Management with Redux",
      "Using Styled Components",
      "Testing with Jest and Enzyme",
    ],
    targetAudience: "This course is designed for developers who want to build modern web applications using React.js."
  },
  mysql: {
    name: "MySQL",
    description: "Learn MySQL, a popular relational database management system. Understand database design, SQL queries, and optimization.",
    className: "mysql",
    icon: faDatabase,
    fee: 20000,
    duration: "2 months",
    prerequisites: [
      "Basic understanding of databases",
      "SQL knowledge is beneficial",
    ],
    content: [
      "Introduction to MySQL",
      "Database Design and Normalization",
      "SQL Queries and Transactions",
      "Indexing and Optimization",
      "Stored Procedures and Triggers",
    ],
    targetAudience: "This course is for beginners and developers looking to deepen their MySQL skills."
  },
  oracle: {
    name: "Oracle",
    description: "Get proficient with Oracle Database. Learn SQL, PL/SQL, and database administration to manage complex systems.",
    className: "oracle",
    icon: faDatabase,
    fee: 20000,
    duration: "4 months",
    prerequisites: [
      "SQL and Database Concepts",
      "Basic knowledge of relational databases",
    ],
    content: [
      "Introduction to Oracle Database",
      "SQL Fundamentals",
      "Advanced SQL Techniques",
      "PL/SQL Programming",
      "Database Security and Performance",
    ],
    targetAudience: "This course is suitable for aspiring database administrators and developers."
  },
  javascript: {
    name: "JavaScript",
    description: "Master JavaScript, the programming language of the web. Learn about variables, functions, and asynchronous programming.",
    className: "javascript",
    icon: faJsSquare,
    fee: 15000,
    duration: "1.5 months",
    prerequisites: [
      "Basic knowledge of HTML and CSS",
      "Familiarity with programming concepts",
    ],
    content: [
      "Introduction to JavaScript",
      "Variables, Functions, and Objects",
      "DOM Manipulation and Events",
      "ES6+ Features",
      "Asynchronous JavaScript and Promises",
      "Modern JavaScript Frameworks",
    ],
    targetAudience: "This course is for web developers aiming to master JavaScript for web application development."
  },
  angular: {
    name: "Angular",
    description: "Explore Angular for building scalable web applications. Understand components, services, and state management.",
    className: "angular",
    icon: faAngular,
    fee: 12000,
    duration: "2.5 months",
    prerequisites: [
      "Proficiency in HTML, CSS, and JavaScript",
      "Understanding of TypeScript",
    ],
    content: [
      "Introduction to Angular",
      "Components and Directives",
      "Services and Dependency Injection",
      "Routing and Navigation",
      "Forms and Validation",
      "HTTP Client and REST API Integration",
    ],
    targetAudience: "This course is for developers interested in building scalable web applications with Angular."
  }
};

export default courses;
