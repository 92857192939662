import React,{ useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import ReactJsDetail from './components/ReactJsDetail';
import PythonDetail from './components/PythonDetail';
import OracleDetail from './components/OracleDetail';
import JavascriptDetail from './components/JavascriptDetail';
import MySQLDetail from './components/MySQLDetail';
import AngularDetail from './components/AngularDetail';
import AboutUsDetail from './components/AboutUsDetail'; 
import Navbar from './components/Navbar';
import Home from './components/Home';
import Courses from './components/Courses';
import Contact from './components/Contact';
import Footer from './components/Footer';

import EducationContent from './components/EducationalContent'; 

const ScrollToSection = () => { 
    const location = useLocation(); 

     useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 0); // Adjust the delay as needed
      }
    }
  }, [location]);

    return null;
};

function App() {
    return (
        <Router>
            <ScrollToSection />
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={
                        <>
                            <Home/>
                            <EducationContent/>
                            <Courses/>
                   
                            <Contact/>
                       </>
                    } />
                  <Route path="/course/:courseName" element={<PythonDetail />} />
                    <Route path="/course/:courseName" element={<ReactJsDetail />} />
                    <Route path="/course/:courseName" element={<OracleDetail />} />
                    <Route path="/course/:courseName" element={<MySQLDetail />} />
                    <Route path="/course/:courseName" element={<JavascriptDetail />} />
                    <Route path="/course/:courseName" element={<AngularDetail  />} />
                    <Route path="/aboutus" element={<AboutUsDetail  />} />
              
                  </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
