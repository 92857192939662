
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import '../assets/css/styles.css';
import EnrollModal from './EnrollModal';
import courses from './coursesData';

const JavaScriptDetail = () => {
  const { courseName } = useParams();
  const course = courses[courseName] || {};
  const [isModalOpen, setModalOpen] = useState(false);

  const handleEnrollClick = () => {
    setModalOpen(true);
  };

  if (!course.name) {
    return <p>Course not found.</p>;
  }

  return (
    <section className="course-detail">
      <h2 className="heading1">
        <span>{course.name}</span>
      </h2>

      <div className="course-info">
        <div className="course-description">
          <p>{course.description}</p>
          <p>Duration: {course.duration}</p>
          <p>Price: ₹{course.fee}</p>

          <h3>Prerequisites:</h3>
          <ul>
            {course.prerequisites.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>

          <h3>Course Content:</h3>
          <ul>
            {course.content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>

          <h3>Target Audience:</h3>
          <p>{course.targetAudience}</p>

          <button onClick={handleEnrollClick} className="btn">
            Enroll Now
          </button>
        </div>
      </div>

      <EnrollModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} course={course} />
    </section>
  );
};

export default JavaScriptDetail;
