
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../assets/css/styles.css';
import courses from './coursesData'; // Adjust path if necessary

const Courses = () => {
  const navigate = useNavigate();

  const handleChooseCourse = (course) => {
    navigate(`/course/${course.className}`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100); // Adjust the timeout if needed
  };

  return (
    <section className="courses" id="courses">
      <h1 className="heading">
        <span>courses</span>
      </h1>
      <div className="box-container">
        {Object.values(courses).map((course, index) => (
          <div className={`box ${course.className}`} key={index}>
            <div className="content">
              <FontAwesomeIcon icon={course.icon} className="course-icon" />
              <h3>{course.name}</h3>
              <p>{course.description}</p>
              <button onClick={() => handleChooseCourse(course)} className="course-btn">Learn More</button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Courses;
