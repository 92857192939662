
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import "../assets/css/EnrollModal.css";
import Loader from './Loader'; // Import the Loader component

const EnrollModal = ({ isOpen, onClose, course }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNo: '',
    courseMode: 'online',
  });
  const [confirmation, setConfirmation] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    const { name, email, contactNo, courseMode } = formData;
    const { name: courseName, duration, fee } = course;

    const emailData = {
      course: {
        name: courseName,
        duration,
        fee,
      },
      student: { name, contactNo, email, courseMode }, 
    };

    try {
      const response = await fetch('https://my-backend-24iw.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        console.log('Email sent successfully');
        setConfirmation(true);
      } else {
        const errorData = await response.json();
        console.error('Failed to send email:', errorData.error);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleConfirmation = () => {
    setConfirmation(false);
    onClose();
    navigate(`/course/${course.className}`); // Redirect to the courses page
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={onClose} />
        {loading && <Loader />} {/* Show loader when loading */}
        {confirmation ? (
          <div className="confirmation-message">
            <h2>Enrollment Successful!</h2>
            <p>You have successfully enrolled in the course.</p>
            <button className="btn" onClick={handleConfirmation}>OK</button>
          </div>
        ) : (
          <>
            <h2>Enroll Now</h2>
            <form onSubmit={handleSubmit}>
              <label>Name</label>
              <input type="text" name="name" value={formData.name} onChange={handleChange} required />

              <label>Email</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />

              <label>Contact Number</label>
              <input type="text" name="contactNo" value={formData.contactNo} onChange={handleChange} required />

              <label>Course Mode</label>
              <select name="courseMode" value={formData.courseMode} onChange={handleChange}>
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>

              <label>Course Name</label>
              <input type="text" name="courseName" value={course.name || ''} readOnly />

              <label>Course Duration</label>
              <input type="text" name="courseDuration" value={course.duration || ''} readOnly />

              <label>Course Fee</label>
              <input type="text" name="courseFee" value={`₹${course.fee || 0}`} readOnly />

              <button type="submit" className="btn">Submit</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default EnrollModal;
