
import React from 'react';
import '../assets/css/styles.css'; // Ensure your CSS file is imported


const AboutUsDetail = () => {
 
  return (
    <section className="about-us-detail" id="aboutus">
 
        <h2 className="heading1">About Us</h2>

        <div className="about-us-info">
         
          <div className="about-us-description">
           <h3>Why Choose SJS Technologies?</h3>
          <p>
            At SJS Technologies, we prioritize student success above all else. Our dedicated faculty members are committed to providing a supportive learning environment where students can thrive academically and personally. Through innovative teaching methods and personalized attention, we ensure that each student receives the guidance and resources they need to achieve their goals. Our comprehensive curriculum covers a wide range of subjects, preparing students for future academic pursuits and professional endeavors.
          </p>
            <h3>Our Mission:</h3>
            <p>
              Our mission is to empower students with the knowledge and skills they need to excel in their chosen fields. We aim to foster a culture of continuous learning and improvement, where students are encouraged to explore their interests and achieve their full potential.
            </p>

            <h3>Our Vision:</h3>
            <p>
              We envision a world where education is accessible to everyone, and where individuals can transform their lives through learning. We strive to be at the forefront of educational innovation, offering programs that are relevant, engaging, and impactful.
            </p>

            <h3>Values:</h3>
            <ul>
              <li>Commitment to Excellence</li>
              <li>Integrity and Ethics</li>
              <li>Innovation and Creativity</li>
              <li>Collaboration and Teamwork</li>
            </ul>

            <h3>Our Programs:</h3>
            <p>
              We offer a wide range of programs in various fields including technology, science, and business. Our courses are designed to provide hands-on experience and practical knowledge that students can apply in real-world scenarios.
            </p>
          </div>
        </div>
  
    </section>
  );
};

export default AboutUsDetail;